<template>
    <v-dialog v-model="dialog.show" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mx-2"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                fab
                small
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            dark
                        >
                            mdi-plus
                        </v-icon>
                    </template>
                    create new log version
                </v-tooltip>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">new log version</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                      <v-col cols="12" sm="12">
                          <v-text-field v-model="logVersionItem.name" label="name"></v-text-field>
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="logVersionItem.description"
                          label="description"
                        ></v-text-field>
                    </v-col>
                    </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-switch
                        v-model="logVersionItem.isDefault"
                        label="is default"
                        class="pa-3"
                      />
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog.show=false"
                  :disabled="dialog.saving"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :disabled="dialog.saving"
                    :loading="dialog.saving"
                    @click="save"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'LogVersionForm',
  data: () => ({
    dialog: {
      saving: false,
      show: false,
    },

    logVersionItem: {
      id: null,
      name: '',
      description: '',
      isDefault: false,
    },
  }),
  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
    }),

    save() {
      this.dialog.saving = false;

      this.axios
        .post('api/admin/logVersion',
          {
            name: this.logVersionItem.name,
            description: this.logVersionItem.description,
            is_default: this.logVersionItem.isDefault,
          }).then((response) => {
          if (!response.data.success) {
            this.setSnack({
              color: 'red',
              text: response.data.message,
            });
            return;
          }
          this.setSnack({
            color: 'green',
            text: 'saved',
          });

          this.$emit('create', response.data.result);

          this.logVersionItem.name = '';
          this.logVersionItem.description = '';
          this.logVersionItem.isDefault = false;
          this.logVersionItem.id = null;

          this.dialog.show = false;
        }).catch((error) => {
          let message = 'error';

          if (error.response && error.response.data.message) {
            message = error.response.data.message;
          }

          this.setSnack({
            snack: true,
            color: 'red',
            text: message,
          });
        }).finally(() => {
          this.dialog.saving = false;
        });
    },
  },
};
</script>
