<template>
  <v-container>

    <v-dialog
        v-model="bindCustomerDialog.isOpen"
        max-width="400"
    >
      <v-card>
        <v-card-title>
          Select Customer
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              for log version:
            </v-row>
            <v-row class="font-weight-bold">
              {{ bindCustomerDialog.logVersionName }}
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                    :item-text="item => `${item.name}`"
                    item-value="id"
                    label="Customer"
                    clearable
                    dense
                    chips
                    small-chips
                    multiple
                    v-model="bindCustomerDialog.customerIds"
                    :items="customers"
                    :menu-props="{ closeOnContentClick: true }"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="bindCustomerDialog.isOpen = false"
          >
            Cancel
          </v-btn>
          <v-btn
              @click="bindCustomer"
              color="blue darken-1"
              text
              :disabled="!bindCustomerDialog.customerIds"
          >
            Bind
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="unbindCustomerDialog.isOpen"
        max-width="400"
    >
      <v-card>
        <v-card-title>
          Unbind Customer
          <span class="font-weight-bold ml-4">
            {{ currentCustomer(unbindCustomerDialog.customerId).name }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              for log version:
            </v-row>
            <v-row class="font-weight-bold">
              {{ unbindCustomerDialog.logVersionName }}
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="unbindCustomerDialog.isOpen = false"
          >
            Cancel
          </v-btn>
          <v-btn
              @click="unbindCustomer"
              color="blue darken-1"
              text
          >
            Unbind
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: [10, 25, 50, -1] }"
      :headers="headers"
      :items="logsVersions"
      :single-expand="true"
    >
      <template v-slot:top>
        <v-toolbar
          class="mt-4"
          flat
        >
          <LogVersionForm v-on:create="addLogVersion" />
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="remove(item.id)">
          <v-icon
              color="red"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.name="{ item }">
        <v-row>
        <v-edit-dialog
          @save="save(itemClone, item)"
          @open="itemClone = {...item}"
        >
          {{ item.name }}
          <template v-slot:input>
            <v-text-field
              v-model="itemClone.name"
              label="Edit"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
        </v-row>
      </template>

      <template v-slot:item.description="{ item }">
        <v-edit-dialog
          @save="save(itemClone, item)"
          @open="itemClone = {...item}"
        >
          <div v-if="item.description" class="font-weight-black">{{ item.description }}</div>
          <div v-else class="font-italic font-weight-thin red--text" v-text="'empty'"/>
          <template v-slot:input>
            <v-text-field
              v-model="itemClone.description"
              label="Edit"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.is_default="{ item }">
        <v-switch
            v-model="item.is_default"
            @change="save(item, item)"
        />
      </template>

      <template v-slot:item.customers="{ item }">
        <v-btn
            text
            color="teal accent-4"
            fab
            small
            @click="callBindCustomerDialog(item.id, item.name)"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <slot>
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-label
                </v-icon>
              </slot>
            </template>
            add customer
          </v-tooltip>
        </v-btn>
          <v-chip
              v-for="customer in item.customers"
              :key="customer.id"
              close
              @click:close="callUnbindCustomerDialog(
                item.id,
                item.name,
                customer.id
              )"
              class="ma-0"
              small
          >
            {{ currentCustomer(customer.id).name }}
          </v-chip>
      </template>

      <template v-slot:item.active="{ item }">
        <v-switch
            v-model="item.is_active"
            @change="save(item, item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import LogVersionForm from '../components/LogVersionForm.vue';

export default {
  name: 'LogVersion',
  components: {
    LogVersionForm,
  },

  data: () => ({
    itemClone: {},
    logsVersions: [],
    customers: [],

    headers: [
      { text: 'id', value: 'id' },
      { text: 'name', value: 'name' },
      { text: 'description', value: 'description' },
      { text: 'customers', value: 'customers' },
      { text: 'is default', value: 'is_default' },
      { text: '', value: 'actions', sortable: false },
    ],
    options: {
      itemsPerPage: 10,
      sortBy: ['id'],
      sortDesc: [false],
      mustSort: true,
      multiSort: false,
    },

    bindCustomerDialog: {
      isOpen: false,
      logVersionName: '',
      customerIds: [],
      logVersionId: null,
    },

    unbindCustomerDialog: {
      isOpen: false,
      logVersionName: '',
      customerId: null,
      logVersionId: null,
    },
  }),

  created() {
    this.fetchCustomersList();
    this.$requestHelpers
      .get('api/admin/logVersion')
      .then((response) => {
        this.logsVersions = response.data;
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            this.$router.push({ name: 'root' });
          }
        }
      });
  },

  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
    }),

    addLogVersion(logVersion) {
      this.logsVersions.push(logVersion);
    },

    save(itemClone, item) {
      this.$requestHelpers
        .patch(`api/admin/logVersion/${item.id}`, itemClone)
        .then((response) => {
          if (response.data.success) {
            Object.assign(item, response.data.result);

            this.setSnack({
              color: 'green',
              text: 'saved',
            });
          } else {
            this.setSnack({
              color: 'red',
              text: response.data.message,
            });
          }
        });
    },

    remove(id) {
      const logVersion = this.logsVersions.find((el) => el.id === id);
      this.$confirm(`Are you sure you want to delete log version ${logVersion.name}?`).then((res) => {
        if (!res) return;

        this.$requestHelpers
          .delete(`api/admin/logVersion/${id}`)
          .then((response) => {
            if (response.data.success) {
              this.setSnack({
                color: 'green',
                text: 'deleted',
              });
              this.logsVersions = this.logsVersions.filter((e) => e.id !== id);
            } else {
              this.setSnack({
                color: 'red',
                text: 'error',
              });
            }
          });
      });
    },

    callBindCustomerDialog(logVersionId, logVersionName) {
      Object.assign(
        this.bindCustomerDialog,
        {
          isOpen: true,
          logVersionName,
          logVersionId,
          customerIds: [],
        },
      );
    },

    bindCustomer() {
      this.$requestHelpers
        .post('api/admin/logVersion/bindCustomer',
          {
            log_version_id: this.bindCustomerDialog.logVersionId,
            customer_ids: this.bindCustomerDialog.customerIds,
          }).then((response) => {
          const item = this.logsVersions.find((el) => el.id === response.data.result.id);

          Object.assign(item, response.data.result);
        }).finally(() => {
          this.bindCustomerDialog.isOpen = false;
        });
    },

    callUnbindCustomerDialog(logVersionId, logVersionName, customerId) {
      Object.assign(
        this.unbindCustomerDialog,
        {
          isOpen: true,
          logVersionName,
          logVersionId,
          customerId,
        },
      );
    },

    unbindCustomer() {
      this.$requestHelpers
        .post('api/admin/logVersion/unbindCustomer',
          {
            log_version_id: this.unbindCustomerDialog.logVersionId,
            customer_id: this.unbindCustomerDialog.customerId,
          }).then((response) => {
          const item = this.logsVersions.find((el) => el.id === response.data.result.id);

          Object.assign(item, response.data.result);
        }).finally(() => {
          this.unbindCustomerDialog.isOpen = false;
        });
    },

    fetchCustomersList() {
      this.$requestHelpers
        .get('api/admin/customer')
        .then((response) => {
          this.customers = response.data;
        });
    },
  },

  computed: {
    currentCustomer() {
      return (id) => {
        const item = this.customers.find((el) => el.id === id);
        return item || { id: null, name: 'n/a' };
      };
    },
  },
};
</script>
